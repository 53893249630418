<template>
  <v-container class="px-0">
    <h1 class="headline pb-4 mx-lg-auto">SEMS Application</h1>
    <v-row>
      <v-col v-if="can('view groups')" cols="12" md="6">
        <v-card>
          <v-card-title>Groups</v-card-title>
          <v-card-text>
            <v-data-table
              :headers="headers.groups"
              :loading="loading"
              :items="desktop.groups"
              @click:row="editItem($event, 'groups')"
              loading-text="Loading... Please wait"
              hide-default-footer
            >
              <template v-slot:item.subgroups="{ item }">
                <span v-for="(subgroup, index) in item.subgroups" :key="subgroup.id">
                  <router-link :to="`/subgroups/${subgroup.id}`" class="base-link">{{ subgroup.title }}</router-link>
                  <span v-if="index != item.subgroups.length - 1">, </span>
                </span>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-actions>
            <v-btn outlined color="primary" class="mb-2" to="/groups">View</v-btn>
            <v-btn v-if="can('add groups')" color="primary" dark class="mb-2" to="/groups/new">New Group</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col v-if="can('view organisations')" cols="12" md="6">
        <v-card>
          <v-card-title>Organisations</v-card-title>
          <v-card-text>
            <v-data-table
              :headers="headers.organisations"
              :items="desktop.organisations"
              @click:row="editItem($event, 'organisations')"
              :loading="loading"
              loading-text="Loading... Please wait"
              hide-default-footer
            >
            </v-data-table>
          </v-card-text>
          <v-card-actions>
            <v-btn outlined color="primary" class="mb-2" to="/organisations">View</v-btn>
            <v-btn color="primary" dark class="mb-2" to="/organisations/new">New Organisations</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-if="can('view people')" cols="12" md="6">
        <v-card>
          <v-card-title>People</v-card-title>
          <v-card-text>
            <v-data-table
              :headers="headers.people"
              :items="desktop.people"
              @click:row="editItem($event, 'people')"
              :loading="loading"
              loading-text="Loading... Please wait"
              hide-default-footer
            >
            </v-data-table>
          </v-card-text>
          <v-card-actions>
            <v-btn outlined color="primary" class="mb-2" to="/people">View</v-btn>
            <v-btn v-if="can('add people')" color="primary" dark class="mb-2" to="/people/new">New People</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col v-if="can('view paperwork')" cols="12" md="6">
        <v-card>
          <v-card-title>Paperwork Due</v-card-title>
          <v-card-text>
            <v-data-table
              :headers="headers.paperwork"
              :items="desktop.paperwork"
              @click:row="editPaperwork($event)"
              :loading="loading"
              loading-text="Loading... Please wait"
              hide-default-footer
            >
              <template v-slot:item.date_due="{ item }"> {{ formatDate(item.date_due) }} </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import store from '@/store/index';
import { mapGetters } from 'vuex';
import { permisionsMixin } from '@/mixins/permisionsMixin';
import { dateMixin } from '@/mixins/dateMixin';

export default {
  name: 'DashboardView',
  beforeRouteEnter(to, from, next) {
    store.dispatch('desktop/getDesktop').then(() => {
      next();
    });
  },
  mixins: [permisionsMixin, dateMixin],
  computed: {
    ...mapGetters('desktop', ['desktop', 'loading'])
  },
  methods: {
    editItem: function (item, path) {
      this.$router.push({ path: `/${path}/${item.id}` });
    },
    editPaperwork(item) {
      this.$router.push({ path: `/subgroups/${item.subgroup_id}/paperwork` });
    }
  },
  data: () => ({
    headers: {
      groups: [
        { text: 'ID', value: 'id' },
        { text: 'Group Name', value: 'title' },
        { text: 'Subgroups', value: 'subgroups' }
      ],
      organisations: [
        { text: 'Name', value: 'title' },
        { text: 'Type', value: 'type' }
      ],
      people: [
        { text: 'First Name', value: 'first_name' },
        { text: 'Surname', value: 'surname' },
        { text: 'Type', value: 'type' }
      ],
      paperwork: [
        { text: 'Paperwork', value: 'title' },
        { text: 'Group Name', value: 'group_name' },
        { text: 'Date', value: 'date_due' }
      ]
    }
  })
};
</script>
